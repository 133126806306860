import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios-dashboard';

import { enqueueSnackbar } from './snackbarSlice';

const initialState = {};

export const enviarGraficosPorMail = createAsyncThunk(
  'notificaciones/enviarGraficosPorMail',
  async ({ data, success}, { dispatch }) => {
    axios
      .post(`/notificaciones/EnviarGraficosPorMail`, data)
      .then((response) => {
        dispatch(
          enqueueSnackbar({
            message: `Gráficos enviados por mail con éxito!`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
            },
          })
        );

        return success && success();
      })
      .catch((error) => {
        let errorMessage = `Error al enviar los gráficos por mail, intente nuevamente.`;
        if (error.response && error.response.data) {
          errorMessage = error.response.data;
        }

        dispatch(
          enqueueSnackbar({
            message: errorMessage,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "error",
            },
          })
        );
      });
  }
);

export const notificacionesSlice = createSlice({
  name: 'notificaciones',
  initialState,
  reducers: {

  }
});

export default notificacionesSlice.reducer;