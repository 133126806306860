import React from "react";
import { useSelector } from 'react-redux';
import { Grid } from "@mui/material";
import ConteinerWithEjecutarPagina from "../../components/UI/ConteinerWithEjecutarPagina/ConteinerWithEjecutarPagina";

const AdministracionInformes = () => {
  sessionStorage.setItem('seccionNombre', 'Administración Informes');
  const ejecucionDePagina = useSelector(state => state.ejecucionPaginas.ejecucionAdministracionInformes);

  return (
    <ConteinerWithEjecutarPagina
      showMultiempresa
      isExecuted={ejecucionDePagina}
    >
      <Grid container>
      </Grid>
    </ConteinerWithEjecutarPagina>
  );
};

export default AdministracionInformes;