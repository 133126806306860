import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createBrowserHistory } from '@remix-run/router';
import { baseUrlAlertas, urlPortal } from '../../assets/shared/urlApps';
import axios from '../../axios-dashboard';
import { storageEmpresaId } from '../../assets/shared/sessionData';

const initialState = {
  token: null,
  error: null,
  loading: false,
  authRedirectPath: '/',
  ajustaInflacion: false,
  userInfo: {}
};

export const login = createAsyncThunk(
  'auth/login',
  async (token) => {
    sessionStorage.setItem("token", token);
    const response = await axios.get(`${baseUrlAlertas()}api/token/CheckToken`);
    return { data: response.data, status: response.status };
  }
);

export const authCheckState = createAsyncThunk(
  'auth/authCheckState',
  async (_, { dispatch }) => {
    window.onload = () => {
      let helperFrameWindow = document.getElementById("helperFrame").contentWindow;
      let getUserId = () => helperFrameWindow.postMessage("getUserId", "*");

      window.addEventListener("message", (event) => {
        if (typeof event.data === "string") {
          if (event.data === "sinToken") {
            dispatch(logout());
            urlPortal();
          }

          if (!sessionStorage.getItem("token")) {
            const payload = {
              token: event.data,
              diferentToken: true
            }
            dispatch(checkToken(payload));
          } else {
            const payload = {
              token: event.data,
              diferentToken: sessionStorage.getItem("token") !== event.data
            }
            dispatch(checkToken(payload));
          }
        }
      });
      getUserId();
    };
  }
);

export const checkToken = createAsyncThunk(
  'auth/checkToken',
  async (payload) => {
    const { token, diferentToken } = payload;
    const idEmpresa = storageEmpresaId();
    const response = await axios.get(`${baseUrlAlertas()}token/CheckToken?aplicacionId=1${idEmpresa ? `&empresaId=${idEmpresa}` : ''}`, { headers: { Authorization: `Bearer ${token}` }, });
    return { data: response.data, status: response.status, diferentToken };
  }
);

const checkAuthTimeout = (state, expiresIn) => {
  setTimeout(() => {
    authLogout(state);
    clearSessionValues();
  }, expiresIn * 1000);
}

const clearSessionValues = () => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('expirationDate');
  sessionStorage.removeItem('monedaId');
  sessionStorage.removeItem('monedaSigno');
  sessionStorage.removeItem('multiempresa');
  sessionStorage.removeItem('empresaId');
  sessionStorage.removeItem('fechaFija');
  sessionStorage.removeItem('userEmail');
  sessionStorage.removeItem('demo');
  sessionStorage.removeItem('sucursalId');
  sessionStorage.removeItem('usuarioId');
  sessionStorage.clear();
};

const fillSessionValues = (user, token) => {
  sessionStorage.setItem("token", token);
  sessionStorage.setItem("expirationDate", user.expiration);
  sessionStorage.setItem("userEmail", user.email);
  sessionStorage.setItem("clienteId", user.clienteId);
  sessionStorage.setItem("cliente", user.cliente);
  sessionStorage.setItem("isAdmin", user.isAdmin);
  sessionStorage.setItem("tieneVendedor", user.tieneVendedor);
  sessionStorage.setItem("fechaFija", user.fechaFija);
  sessionStorage.setItem("multiempresa", user.tieneMultiempresa);
  sessionStorage.setItem("empresaId", user.empresaPorDefectoId);
  sessionStorage.setItem("empresaNombre", user.empresaPorDefectoNombre);
  sessionStorage.setItem("monedaId", user.monedaId);
  user.monedaSigno && sessionStorage.setItem("monedaSigno", user.monedaSigno.trim());
  sessionStorage.setItem("gauss", user.gauss);
  sessionStorage.setItem("demo", user.demo);
  sessionStorage.setItem('sucursalId', user.sucursalId);
  sessionStorage.setItem('usuarioId', user.usuarioId);
  sessionStorage.setItem('ConfiguraComprobantes', user.configuraComprobantes);
};

const authLogout = (state) => {
  state.loading = false;
  state.error = null;
  state.token = null;
  state.authRedirectPath = '/';
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      authLogout(state);
      clearSessionValues();
    },
    setAuthRedirectPath: (state, action) => {
      state.authRedirectPath = action.path;
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.user;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.token = null;
        state.error = null;
        state.loading = true;
        state.authRedirectPath = '/';
      })
      .addCase(login.fulfilled, (state, response) => {
        if (response.payload.status !== 200) {
          authLogout(state);
          clearSessionValues();
        } else {
          const user = response.payload.data;
          state.error = null;
          state.loading = false;
          state.userInfo = user;
          createBrowserHistory().push("/");
          fillSessionValues(user, user.token);
          state.token = user.token;
          checkAuthTimeout(state, 24 * 60 * 60);
        }
      })
      .addCase(login.rejected, (state, error) => {
        state.loading = false;
        state.error = error.response
          ? error.response.data
          : "Se produjo un error conectando con el servidor";
        authLogout(state);
        clearSessionValues();
      })
      .addCase(checkToken.pending, (state) => {
        state.token = null;
        state.error = null;
        state.loading = true;
        state.authRedirectPath = '/';
      })
      .addCase(checkToken.fulfilled, (state, response) => {
        if (response.payload.status !== 200) {
          authLogout(state);
          clearSessionValues();
        } else {
          const user = response.payload.data;
          state.error = null;
          state.loading = false;
          state.userInfo = user;
          fillSessionValues(user, user.token);

          if (response.payload.diferentToken) {
            sessionStorage.setItem("empresaId", user.empresaPorDefectoId);
            sessionStorage.setItem("empresaNombre", user.empresaPorDefectoNombre);
          }

          state.token = user.token;
          state.ajustaInflacion = user.ajustaInflacion;
          checkAuthTimeout(state, 24 * 60 * 60);
        }
      })
      .addCase(checkToken.rejected, (state, error) => {
        state.loading = false;
        state.error = error.response
          ? error.response.data
          : "Se produjo un error conectando con el servidor";
        authLogout(state);
        clearSessionValues();
      });
  },
});

export const { logout, setAuthRedirectPath, setUserInfo } = authSlice.actions;

export default authSlice.reducer;