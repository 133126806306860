import React, { Fragment, useState } from 'react';
import { Button, Divider, Grid, IconButton, MenuItem, TextField, Tooltip, Typography } from "@mui/material";
import { customControls } from '../../../assets/shared/controls';
import { checkValidity } from '../../../assets/shared/utility';
import classes from './TipoAlertas.module.css';
import Input from '../Input/Input';
import { Add, Delete } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { actualizarTipoAlerta } from '../../../store/slices/tipoAlertaSlice';

const inputLabelProps = {
  style: {
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.87)',
    width: '100%',
  }
}

const TipoAlertasDetalle = (props) => {
  const dispatch = useDispatch();
  const { alerta, canEdit, onCloseModal } = props;
  const [configuraciones, setConfiguraciones] = useState(alerta && alerta.configuraciones);
  const tiposUI = useSelector(state => state.tipoAlertas.tiposUI.items);

  const styleSwitch = {
    width: '100%',
    marginLeft: '0px',
    marginRight: '20px',
    justifyContent: 'center',
    alignItems: 'flex-start'
  }

  const [formIsValid, setFormIsValid] = useState(false);
  const [controls, setControls] = useState({
    tituloGauss: {
      name: "tituloGauss",
      label: "Título Gauss",
      placeholder: "",
      elementType: "input",
      elementConfig: {
        type: "text",
        inputLabelProps
      },
      disabled: !canEdit,
      value: alerta ? alerta.tituloGauss : '',
      validation: {},
      valid: true,
      touched: false,
      errorMessage: "",
    },
    descripcionGauss: {
      name: "descripcionGauss",
      label: "Descripción Gauss",
      placeholder: "",
      elementType: "input",
      elementConfig: {
        type: "text",
        inputLabelProps
      },
      disabled: !canEdit,
      value: alerta ? alerta.descripcionGauss : '',
      validation: {},
      valid: true,
      touched: false,
      errorMessage: "",
    },
    categoria: {
      name: "categoria",
      label: "Categoria de la alerta",
      placeholder: "",
      elementType: "input",
      elementConfig: {
        type: "text",
        inputLabelProps
      },
      disabled: !canEdit,
      value: alerta ? alerta.categoria : '',
      validation: {},
      valid: true,
      touched: false,
      errorMessage: "",
    },
    jalisco: {
      elementType: "switch",
      name: "jalisco",
      label: "Jalisco",
      elementConfig: {
        type: "switch",
      },
      validation: {},
      disabled: !canEdit,
      value: alerta.jalisco,
      valid: true,
      touched: false,
      errorMessage: "",
      style: styleSwitch,
      labelPlacement: 'top'
    },
    query: {
      name: "query",
      label: "Query Alerta",
      placeholder: "",
      elementType: "input",
      elementConfig: {
        type: "textarea",
        inputLabelProps
      },
      disabled: !canEdit,
      value: alerta ? alerta.query : '',
      validation: {},
      valid: true,
      touched: false,
      errorMessage: "",
    },
    habilitaCantidadDias: {
      elementType: "switch",
      name: "habilitaCantidadDias",
      label: "Habilita Cant. Dias",
      elementConfig: {
        type: "switch",
      },
      validation: {},
      disabled: !canEdit,
      value: alerta.habilitaCantidadDias,
      valid: true,
      touched: false,
      errorMessage: "",
      style: styleSwitch,
      labelPlacement: 'top'
    },
    cantidadMaxConfiguraciones: {
      name: "cantidadMaxConfiguraciones",
      label: "Cant. Max. Configuración",
      placeholder: "",
      elementType: "input",
      elementConfig: {
        type: "number",
        inputLabelProps
      },
      disabled: !canEdit,
      value: alerta ? alerta.cantidadMaxConfiguraciones : '',
      validation: {},
      valid: true,
      touched: false,
      errorMessage: "",
    },
    cantidadMaxCreaciones: {
      name: "cantidadMaxCreaciones",
      label: "Cant. Max. Creación",
      placeholder: "",
      elementType: "input",
      elementConfig: {
        type: "number",
        inputLabelProps
      },
      disabled: !canEdit,
      value: alerta ? alerta.cantidadMaxCreaciones : '',
      validation: {},
      valid: true,
      touched: false,
      errorMessage: "",
    },
  });

  const inputValueChangedHandler = (event, inputId) => {
    const updatedControl = { ...controls[inputId] };

    const value = updatedControl.elementType === "checkbox"
      ? event.target.checked
      : updatedControl.elementType === "select"
        ? event
        : updatedControl.elementType === "switch"
          ? !updatedControl.value
          : event.target.value;

    if (updatedControl.elementType === 'file') {
      updatedControl.file = event.target.files[0];
      updatedControl.value = event.target.files[0].name;
    } else {
      updatedControl.value = value;
    }

    const [isValid, errorMessage] = checkValidity(value, controls[inputId].validation);
    updatedControl.valid = isValid;
    updatedControl.errorMessage = errorMessage;
    updatedControl.touched = true;

    const updatedControls = {
      ...controls,
      [inputId]: updatedControl,
    };

    let formIsValid = isValid;
    for (let formElementKey in updatedControls) {
      if (updatedControls[formElementKey].validation) {
        const [updatedControlValid, updatedControlErrorMessage] = checkValidity(updatedControls[formElementKey].value, updatedControls[formElementKey].validation);
        formIsValid = formIsValid && updatedControlValid;

        if (!updatedControlValid) {
          updatedControls[formElementKey].valid = updatedControlValid;
          updatedControls[formElementKey].errorMessage = updatedControlErrorMessage;
          updatedControls[formElementKey].touched = true;
        }
      }
    }

    setControls(updatedControls);
    setFormIsValid(formIsValid);
  };

  let form = customControls(controls, inputValueChangedHandler);

  const onAddConfig = (alerta) => {
    if (alerta && alerta.configuraciones) {
      var items = [].concat(configuraciones);
      items.push({
        id: 0,
        idTipoAlerta: alerta.id,
        idTipoInforme: 0,
        display: '',
        query: '',
        campo: ''
      })

      setConfiguraciones(items);
    }
  }

  const updateConfig = (config) => {
    var tempConfigs = [];
    configuraciones.forEach((c) => {
      var tempConfig = { ...c };
      if (tempConfig.id === config.id) {
        tempConfig.display = config.display;
        tempConfig.id = config.id;
        tempConfig.idTipoAlerta = config.idTipoAlerta;
        tempConfig.idTipoInforme = config.idTipoInforme;
        tempConfig.idTipoUI = config.idTipoUI;
        tempConfig.query = config.query;
        tempConfig.campo = config.campo;
      }

      tempConfigs.push(tempConfig);
    });

    setConfiguraciones(tempConfigs);
  }

  const onCampoChange = (event, config) => {
    var tempConfig = { ...config };
    tempConfig.campo = event.target.value;
    updateConfig(tempConfig);
  }

  const onDisplayChange = (event, config) => {
    var tempConfig = { ...config };
    tempConfig.display = event.target.value;
    updateConfig(tempConfig);
  }

  const onTipoUIChange = (event, config) => {
    var tempConfig = { ...config };
    tempConfig.idTipoUI = event.target.value;
    updateConfig(tempConfig);
  }

  const onQueryChange = (event, config) => {
    var tempConfig = { ...config };
    tempConfig.query = event.target.value;
    updateConfig(tempConfig);
  }

  const onDeleteConfig = (index) => {
    var tempConfig = [].concat(configuraciones);
    tempConfig.splice(index, 1);
    setConfiguraciones(tempConfig);
  };

  const onGuardarCambiosClick = () => {
    var updateAlerta = {
      id: alerta.id,
      tituloGauss: controls.tituloGauss.value,
      descripcionGauss: controls.descripcionGauss.value,
      categoria: controls.categoria.value,
      jalisco: controls.jalisco.value,
      query: controls.query.value,
      habilitaCantidadDias: controls.habilitaCantidadDias.value,
      cantidadMaxConfiguraciones: controls.cantidadMaxConfiguraciones.value,
      cantidadMaxCreaciones: controls.cantidadMaxCreaciones.value,
      configuraciones
    };

    dispatch(actualizarTipoAlerta({ alerta: updateAlerta, onSuccess: onCloseModal, onError: onCloseModal }));
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={6}>
          {form[0]}
        </Grid>
        <Grid item xs={6}>
          {form[1]}
        </Grid>
        <Grid item xs={6} lg={3}>
          {form[2]}
        </Grid>
        <Grid item xs={6} lg={1}>
          {form[3]}
        </Grid>
        <Grid item xs={4} lg={2}>
          {form[5]}
        </Grid>
        <Grid item xs={4} lg={3}>
          {form[6]}
        </Grid>
        <Grid item xs={4} lg={3}>
          {form[7]}
        </Grid>
        <Grid item xs={12}>
          {form[4]}
        </Grid>
        {configuraciones && (
          <Fragment>
            <Grid item xs={11}>
              <Typography variant="h6">
                <strong>Configuraciones</strong>
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Tooltip title={"Agregar configuración"} placement='top-end'>
                <IconButton onClick={() => onAddConfig(alerta)}>
                  <Add />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={12} container spacing={1} style={{ maxHeight: '350px', overflowY: 'auto' }}>
              {configuraciones && configuraciones.map((config, index) => {
                return (
                  <Grid item xs={12} container spacing={1} key={index} className={classes.ItemCard} >
                    <Grid item xs={4}>
                      <Input
                        key={index}
                        name='campo'
                        label="Campo"
                        placeholder="Campo"
                        elementType="input"
                        elementConfig={{
                          type: "text",
                          inputLabelProps
                        }}
                        value={config.campo}
                        shouldValidate={false}
                        touched={false}
                        errorMessage=""
                        disabled={!canEdit}
                        required={true}
                        valueChanged={(event) => onCampoChange(event, config)}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        key={index}
                        name='display'
                        label="Display"
                        placeholder="Display"
                        elementType="input"
                        elementConfig={{
                          type: "text",
                          inputLabelProps
                        }}
                        value={config.display}
                        shouldValidate={false}
                        touched={false}
                        errorMessage=""
                        disabled={!canEdit}
                        required={true}
                        valueChanged={(event) => onDisplayChange(event, config)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        id={`select-tipoUI-${index}`}
                        select
                        label="Tipo UI"
                        variant="standard"
                        value={config && config.idTipoUI}
                        errorMessage=""
                        disabled={!canEdit}
                        required={true}
                        InputLabelProps={inputLabelProps}
                        fullWidth={true}
                        onChange={(event) => onTipoUIChange(event, config)}
                      >
                        {tiposUI && tiposUI.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={1} style={{ textAlign: 'end' }}>
                      <IconButton onClick={() => onDeleteConfig(index)}>
                        <Delete />
                      </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id={`configuration-query-${index}`}
                        type="input"
                        label="Query Configuración"
                        placeholder="Query Configuración"
                        value={config.query}
                        variant='standard'
                        disabled={!canEdit}
                        required={true}
                        InputLabelProps={inputLabelProps}
                        fullWidth={true}
                        onChange={(event) => onQueryChange(event, config)}
                      />
                    </Grid>
                  </Grid>
                )
              })}
            </Grid>
          </Fragment>
        )}
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} container spacing={1} direction='row' justifyContent='flex-end'>
        <Grid item xs={6} lg={3}>
          <Button disabled={!formIsValid} onClick={() => onGuardarCambiosClick()}
            color='primary' variant='contained'
            style={{ paddingInline: '15px', color: 'white' }}>
            Guardar cambios
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}


export default TipoAlertasDetalle;