import React, { Fragment } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useDispatch } from 'react-redux';
import classes from './Alertas.module.css';
import { ejecutarAlerta } from "../../../store/slices/alertasSlice";
import { closeAlertaModal } from "../../../store/slices/alertasSlice";
import { useSelector } from "react-redux";
import Spinner from "../Spinner/Spinner";

const AlertaConfiguradaEjecutar = (props) => {
  const dispatch = useDispatch();
  const { alerta } = props;
  const ejecutando = useSelector(state => state.alertas.ejecutarAlerta.ejecutando);
  const ejecutada = useSelector(state => state.alertas.ejecutarAlerta.ejecutada);
  const error = useSelector(state => state.alertas.ejecutarAlerta.error);

  const renderEjecutada = () => {
    return (
      <Fragment>
        <Grid item xs={12} style={{ marginBottom: '10px' }}>
          <Typography variant="h6" style={{ textAlign: 'center' }}>
            Alerta ejecutada correctamente.
          </Typography>
        </Grid>
        <Grid item xs={12} container spacing={1} justifyContent='center'>
          <Grid item xs={6} lg={3}>
            <Button
              onClick={() => dispatch(closeAlertaModal())}
              className={classes.ModalButton}
              variant="contained"
              color="primary">
              Aceptar
            </Button>
          </Grid>
        </Grid>
      </Fragment>
    )
  }

  const renderError = () => {
    return (
      <Fragment>
        <Grid item xs={12} style={{ marginBottom: '10px' }}>
          <Typography variant="h6" style={{ textAlign: 'center' }}>
            {'Ha ocurrido un error al ejecutar la alerta' + (alerta ? ` ${alerta.tituloAlerta}` : '') + '. Desea reintentar nuevamente?'}
          </Typography>
        </Grid>
        <Grid item xs={12} container spacing={1} justifyContent='center'>
          <Grid item xs={6} lg={3}>
            <Button
              onClick={() => dispatch(ejecutarAlerta(alerta.id))}
              className={classes.ModalButton}
              variant="contained"
              color="primary">
              Ejecutar
            </Button>
          </Grid>
          <Grid item xs={6} lg={3}>
            <Button
              onClick={() => dispatch(closeAlertaModal())}
              className={classes.ModalButton}
              variant="contained"
              style={{ backgroundImage: "linear-gradient(45deg, #fbb040, #f15a29)" }}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </Fragment>
    )
  }

  const renderEjecutar = () => {
    return (
      <Fragment>
        <Grid item xs={12} style={{ marginBottom: '10px' }}>
          <Typography variant="h6" style={{ textAlign: 'center' }}>
            {'¿Confirma la ejecución de la alerta' + (alerta ? ` ${alerta.tituloAlerta}` : '') + '?'}
          </Typography>
        </Grid>
        <Grid item xs={12} container spacing={1} justifyContent='center'>
          <Grid item xs={6} lg={3}>
            <Button
              onClick={() => dispatch(ejecutarAlerta(alerta.id))}
              className={classes.ModalButton}
              variant="contained"
              color="primary">
              Ejecutar
            </Button>
          </Grid>
          <Grid item xs={6} lg={3}>
            <Button
              onClick={() => dispatch(closeAlertaModal())}
              className={classes.ModalButton}
              variant="contained"
              style={{ backgroundImage: "linear-gradient(45deg, #fbb040, #f15a29)" }}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </Fragment>
    )
  }

  return (
    <Grid container spacing={1} direction='column' justifyContent='center' alignContent='center' style={{ minWidth: '550px', minHeight: '150px' }}>
      {ejecutando ? (
        <Grid item xs={12} style={{ marginBottom: '10px' }}>
          <Spinner />
        </Grid>
      ) : ejecutada
        ? error ? renderError() : renderEjecutada()
        : renderEjecutar()
      }
    </Grid>
  )
}

export default AlertaConfiguradaEjecutar;